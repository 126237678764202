#sidebar {
  width: 200px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  box-shadow: -1px 0 0 0 #e3e8ee inset;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  nav::-webkit-scrollbar {
    padding: 2px;
    width: 12px;
    height: 12px;
    background: transparent;
  }

  nav::-webkit-scrollbar-thumb {
    background: #e0e4e9;
    border-radius: 12px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  nav:hover::-webkit-scrollbar-thumb {
    background: #c1c9d2;
    border-radius: 12px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  nav {
    top: 40px;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    height: 100%;
  }
}

#sidebar-heading {
  width: 200px;
  min-height: 30px;
  height: 30px;
  box-shadow: 0 -1px 0 0 #e3e8ee inset;
  text-align: center;
  padding-top: 10px;
  font-weight: 500;
}

#menu {
  padding-left: 15px;
  font-size: 0.9em;

  li {
    list-style-type: none;
    display: list-item;
    margin-left: 0;
    margin-bottom: 10px;
  }

  a {
    color: #71798a;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: #1e2231;
    }
  }

  .active,
  .active:hover {
    color: #3955d3;
  }
}

footer {
  font-size: 0.8em;
  padding: 5px;
  flex: 1;
  box-shadow: 0 0 0 1px #e3e8ee inset;

  span{
    display: block;
  }
}

#small-menu {
  display: none;
}

@media screen and (max-width: 44em) {

  #small-menu {
    display: block;
  }

  #sidebar-heading {
    display: none;
  }

  nav {
    display: none;
  }

  #sidebar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 40px;
    min-height: auto;
    box-shadow: none;
    display: block;
    z-index: 100;
    width:100%;
    box-shadow: 0  -1px 0 #e3e8ee inset;
    background-color: white;

    #menu-select-label{
      position: absolute;
      left: 10px;
      z-index: 99;
      background-color: white;
      text-transform: uppercase;
      font-size: 0.8em;
      color:#697386;
      font-weight: 500;
      border: none;
      box-shadow: 0  -1px 0 #e3e8ee inset;
      height: 40px;
      line-height: 40px;
      cursor: hand;

      .down-arrow{
        fill: #697386;
      }
    }

    select {
      position: absolute;
      left: 10px;
      z-index: 100;
      background: transparent;
      appearance: none;
      color: hsla(0,0%,100%,0);
      height: 40px;
      width: 100px;
      border: none;
      cursor: hand;

      &:focus{
        outline: none;
      }

      option{
        color: black;
      }
    }

    footer{
      display: none;
    }
  }
}
